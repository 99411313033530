

































































import { Vue, Component } from "vue-property-decorator";
import { Input } from "element-ui";
import { JWTStore, UserStore, MetaStore } from "@/store/modules";
import { resetPassword } from "@/store/models/jwt";
import { setJWT, clearJWT } from "@/store/API/api";
import { MetaInfo } from "vue-meta";

@Component({
  components: {
    Input
  },
  metaInfo(): MetaInfo {
    return {
      title: `${this.$t("resetYourPassword")}`,
      meta: [
        { name: "keyword", content: "Reset Password" },
        {
          name: "description",
          content: "Reset Password",
          vmid: "general"
        }
      ]
    };
  }
})
export default class Resetpassword extends Vue {
  payload: resetPassword = {
    uid: "",
    token: "",
    new_password: "",
    re_new_password: ""
  };

  get mobileScreen() {
    return MetaStore.mobileScreen;
  }

  checkIfRequired(event: any) {
    let index = this.$validator.fields.items.findIndex(x => x.name == event);
    if (index >= 0 && this.$validator.fields.items[index].flags["required"]) {
      return true;
    }
    return false;
  }

  created() {
    this.payload.uid = this.$route.params.uid;
    this.payload.token = this.$route.params.token;
  }

  async submit(e: any) {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      let error: boolean = false;
      await JWTStore.resetPassword(this.payload).catch(err => {
        error = true;
      });
      if (!error) {
        this.$router.push({ name: "ChangeSuccess" }).catch(error => {});
      }
    }
  }
}
