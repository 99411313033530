




















import { Vue, Component } from "vue-property-decorator";
import { Input } from "element-ui";
import { eventHub } from "@/utils/eventHub";
import { MetaInfo } from "vue-meta";

@Component({
  components: {
    Input
  },
  metaInfo(): MetaInfo {
    return {
      title: `${this.$t("passwordResetSuccessful")}`,
      meta: [
        { name: "keyword", content: "Reset Password" },
        {
          name: "description",
          content: "Reset Password",
          vmid: "general"
        }
      ]
    };
  }
})
export default class Changesuccess extends Vue {
  emitGloballogin() {
    this.$router.push({ name: "Home" }).catch(error => {});
    eventHub.$emit("loginClick");
  }

  created() {
    eventHub.$emit("reloader");
  }
}
