var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"loginWrapper"},[_c('div',{staticClass:"loginBox"},[_c('h2',{staticClass:"centering"},[_vm._v(_vm._s(_vm.$t("resetYourPassword")))]),_c('p',[_vm._v(_vm._s(_vm.$t("passwordResetInstruction")))]),_c('div',{staticClass:"loginInner"},[_c('div',{staticClass:"gapBottom"},[_c('el-form',{attrs:{"label-position":"top","inline":""}},[_c('el-form-item',{class:{
                'is-error': _vm.vErrors.has(_vm.$t('newPassword')),
                'is-required': _vm.checkIfRequired(_vm.$t('newPassword')),
                fullWidth: true
              },attrs:{"label":_vm.$t('newPassword')}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":_vm.$t('newPassword'),"placeholder":_vm.$t('enterNewPassword'),"show-password":false,"type":"password"},model:{value:(_vm.payload.new_password),callback:function ($$v) {_vm.$set(_vm.payload, "new_password", $$v)},expression:"payload.new_password"}}),(_vm.vErrors.has(_vm.$t('newPassword')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("newPassword")))+" ")]):_vm._e()],1),_c('el-form-item',{class:{
                'is-error': _vm.vErrors.has(_vm.$t('repeatNewPassword')),
                'is-required': _vm.checkIfRequired(_vm.$t('repeatNewPassword')),
                fullWidth: true
              },attrs:{"label":_vm.$t('repeatNewPassword')}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":_vm.$t('repeatNewPassword'),"placeholder":_vm.$t('enterRepeatNewPassword'),"show-password":false,"type":"password"},model:{value:(_vm.payload.re_new_password),callback:function ($$v) {_vm.$set(_vm.payload, "re_new_password", $$v)},expression:"payload.re_new_password"}}),(_vm.vErrors.has(_vm.$t('repeatNewPassword')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("repeatNewPassword")))+" ")]):_vm._e()],1),_c('el-form-item',{staticClass:"fullWidth"},[_c('el-button',{staticClass:"fullWidth",attrs:{"type":"success"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t("submit")))])],1)],1)],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }